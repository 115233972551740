import {request} from "@/plugins/axios";

export function getCIndex (params) {
  return request({
      url: '/contactEmployeeCode/index',
      method: 'get',
      params: params
  })
}

export function getinviteRecord (params) {
  return request({
      url: '/contactEmployeeCode/inviteRecord',
      method: 'get',
      params: params
  })
}